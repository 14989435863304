import { getSiteURL } from '@/lib/get-site-url';
import type { ColorScheme, PrimaryColor } from '@/styles/theme/types';

export interface Config {
    site: {
        name: string;
        description: string;
        colorScheme: ColorScheme;
        primaryColor: PrimaryColor;
        themeColor: string;
        url: string;
        version: string;
    };
}

export const config = {
    site: {
        name: 'Hydrotech CPQ Admin',
        description: '',
        colorScheme: 'light',
        themeColor: '#090a0b',
        primaryColor: 'neonBlue',
        url: getSiteURL(),
        version: process.env.NEXT_PUBLIC_SITE_VERSION || '0.0.0',
    },
} satisfies Config;
