import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

import { MuiAlert } from './alert';
import { MuiAvatar } from './avatar';
import { MuiBackdrop } from './backdrop';
import { MuiButton } from './button';
import { MuiButtonBase } from './button-base';
import { MuiButtonGroup } from './button-group';
import { MuiCard } from './card';
import { MuiCardActions } from './card-actions';
import { MuiCardContent } from './card-content';
import { MuiCardHeader } from './card-header';
import { MuiCheckbox } from './checkbox';
import { MuiChip } from './chip';
import { MuiFilledInput } from './filled-input';
import { MuiFormControlLabel } from './form-control-label';
import { MuiFormGroup } from './form-group';
import { MuiFormLabel } from './form-label';
import { MuiIconButton } from './icon-button';
import { MuiInput } from './input';
import { MuiInputBase } from './input-base';
import { MuiInputLabel } from './input-label';
import { MuiLinearProgress } from './linear-progress';
import { MuiLink } from './link';
import { MuiList } from './list';
import { MuiListItem } from './list-item';
import { MuiListItemAvatar } from './list-item-avatar';
import { MuiListItemButton } from './list-item-button';
import { MuiListItemIcon } from './list-item-icon';
import { MuiListItemText } from './list-item-text';
import { MuiMenu } from './menu';
import { MuiMenuItem } from './menu-item';
import { MuiOutlinedInput } from './outlined-input';
import { MuiPaper } from './paper';
import { MuiPopover } from './popover';
import { MuiRadio } from './radio';
import { MuiSelect } from './select';
import { MuiStack } from './stack';
import { MuiSwitch } from './switch';
import { MuiTab } from './tab';
import { MuiTableBody } from './table-body';
import { MuiTableCell } from './table-cell';
import { MuiTableHead } from './table-head';
import { MuiTablePagination } from './table-pagination';
import { MuiTabs } from './tabs';
import { MuiTimelineConnector } from './timeline-connector';
import { MuiToggleButton } from './toggle-button';
import { MuiToggleButtonGroup } from './toggle-button-group';
import { MuiTooltip } from './tooltip';

export const components = {
    MuiAlert,
    MuiAvatar,
    MuiBackdrop,
    MuiButton,
    MuiButtonBase,
    MuiButtonGroup,
    MuiCard,
    MuiCardActions,
    MuiCardContent,
    MuiCardHeader,
    MuiCheckbox,
    MuiChip,
    MuiFilledInput,
    MuiFormControlLabel,
    MuiFormGroup,
    MuiFormLabel,
    MuiIconButton,
    MuiInput,
    MuiInputBase,
    MuiInputLabel,
    MuiLinearProgress,
    MuiLink,
    MuiList,
    MuiListItem,
    MuiListItemAvatar,
    MuiListItemButton,
    MuiListItemIcon,
    MuiListItemText,
    MuiMenu,
    MuiMenuItem,
    MuiOutlinedInput,
    MuiPaper,
    MuiPopover,
    MuiRadio,
    MuiSelect,
    MuiStack,
    MuiSwitch,
    MuiTab,
    MuiTableBody,
    MuiTableCell,
    MuiTableHead,
    MuiTablePagination,
    MuiTabs,
    MuiTimelineConnector,
    MuiToggleButton,
    MuiToggleButtonGroup,
    MuiTooltip,
} satisfies Components<Theme>;
