import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getAuthOrRefreshAction } from '@/auth/server-actions';

export const authLink = setContext(async (operation, { headers }) => {
    const excludedOperations = ['VerifyCredentials', 'VerifyJsonWebToken', 'RefreshToken'];

    const operationName = operation.operationName || '';

    if (excludedOperations.includes(operationName)) {
        return { headers };
    }

    const jwtToken = await getAuthOrRefreshAction();

    if (jwtToken) {
        return {
            headers: {
                ...headers,
                Authorization: jwtToken,
            },
        };
    }

    return { headers };
});

const backendDomain = process.env.NEXT_PUBLIC_BACKEND_DOMAIN;

const httpLink = new HttpLink({
    uri: `${backendDomain}/graphql`,
    credentials: 'include',
    fetchOptions: { cache: 'no-store' },
});

export const link = ApolloLink.from([authLink, httpLink]);
