'use client';

import type { PropsWithChildren } from 'react';
import {
    ApolloClient,
    ApolloNextAppProvider,
    InMemoryCache,
} from '@apollo/experimental-nextjs-app-support';

import { link } from "@/auth/ApolloLink";

function createClient() {
    return new ApolloClient({
        cache: new InMemoryCache(),
        link,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only',
            },
        },
    });
}

export function ApolloProvider({ children }: PropsWithChildren) {
    return (
        <ApolloNextAppProvider makeClient={createClient}>
            {children}
        </ApolloNextAppProvider>
    );
}
